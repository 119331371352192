<template>
  <MainLayout back-to="/" :title="queueTitle">
    <template v-slot:header-right-mobile>
      <button class="button is-text" @click="isNewIssueModalActive = true">
        <faicon icon="plus"></faicon>
      </button>
    </template>
    <template v-slot:menu>
      <div class="menu">
        <p class="menu-label">{{ $t('support.filters.title') }}</p>
        <ul class="menu-list">
          <li>
            <router-link tag="a" to="/manage/support/queue/unresolved">{{ $t('support.filters.newOpen') }}</router-link>
          </li>
          <li>
            <router-link tag="a" to="/manage/support/queue/closed">{{ $t('support.filters.closed') }}</router-link>
          </li>
          <li>
            <router-link tag="a" to="/manage/support/queue/all">{{ $t('support.filters.all') }}</router-link>
          </li>
        </ul>
      </div>
    </template>
    <template v-slot:content>
      <div class="has-margin-top">
        <div v-if="!isLoading">
          <div v-if="issues.length > 1" class="has-margin-bottom is-meta has-text-centered">
            {{ issues.length }} {{ $tc('support.ticket.label', 2) }}
          </div>
          <div
            v-if="issues.length > 1"
            class="is-hidden-tablet buttons is-flex is-flex-justify-center has-text-centered is-outlined"
          >
            <router-link class="button is-small" tag="button" to="/manage/support/queue/unresolved">{{
              $t('support.filters.newOpen')
            }}</router-link>
            <router-link class="button is-small" tag="button" to="/manage/support/queue/closed">{{
              $t('support.filters.closed')
            }}</router-link>
            <router-link class="button is-small" tag="button" to="/manage/support/queue/all">{{
              $t('support.filters.all')
            }}</router-link>
          </div>
          <div v-if="issues.length">
            <RecordManageIssue v-for="issue in issues" :key="issue.key" :item="issue"></RecordManageIssue>
          </div>
          <div v-else class="empty-hero-box">
            <p class="has-margin">{{ $t('support.noTickets') }}!</p>
          </div>
        </div>
        <ContentLoader v-else />
      </div>
    </template>
  </MainLayout>
</template>

<script>
import MainLayout from '@/components/layouts/MainLayout'

import ContentLoader from '@/components/utilities/ContentLoader'
import RecordManageIssue from '@/components/records/manage/RecordManageIssue'
import SupportService from '@/services/SupportService'

export default {
  components: {
    MainLayout,
    RecordManageIssue,
    ContentLoader,
  },
  data() {
    return {
      issues: [],
      isLoading: false,
    }
  },
  computed: {
    queueKey() {
      return this.$route.params.key
    },
    queueTitle() {
      const queueTitleMap = {
        unresolved: this.$t('support.filters.newOpen'),
        closed: this.$t('support.filters.closed'),
        all: this.$t('support.filters.all'),
      }
      return queueTitleMap[this.queueKey]
    },
  },
  watch: {
    $route() {
      this.getIssues(this.queueKey)
    },
  },
  mounted() {
    this.getIssues(this.queueKey)
  },
  methods: {
    async getIssues() {
      this.isLoading = true
      const issues = await SupportService.getAllAssignedIssues(this.queueKey)
      this.issues = Object.freeze(issues)
      this.isLoading = false
    },
  },
}
</script>
