<template>
  <div class="record">
    <div class="record-content">
      <div class="record-header is-flex">
        <div class="has-margin-right-small is-meta">
          <span class="has-text-weight-bold has-margin-right">#{{ item.key }}</span>
          <span>{{ item.created_date | formatDateTime }}</span>
        </div>
        <div>{{ item.procedure.code }}</div>
      </div>
      <div class="record-title has-margin-top-small is-flex is-flex-align-center is-flex-space-between">
        <div class="has-margin-right-small">
          <div v-if="item.status == 'new'" class="is-warning tag">{{ $t('support.status.new') }}</div>
          <div v-else-if="item.status == 'open'" class="is-primary tag">{{ $t('support.status.open') }}</div>
          <div v-else class="is-black tag">{{ $t('support.status.closed') }}</div>
        </div>
        <a class="is-flex is-flex-align-center" @click="$router.push('/manage/support/issue/' + item.key)">
          <span class="is-size-4 has-text-weight-bold">
            <v-clamp autoresize :max-lines="1">{{ item.subject }}</v-clamp>
          </span>
        </a>
      </div>
      <div v-if="item.status != 'closed'" class="record-meta is-small has-margin-top-small">
        <v-clamp class="do-break-word" autoresize :max-lines="2">{{ item.description | sanitizeAnyHtml }}</v-clamp>
      </div>
      <div class="is-flex is-flex-align-center is-flex-space-between has-margin-top-small">
        <div>
          <div class="is-flex">
            <faicon class="has-margin-right-small" icon="user-circle"></faicon>
            <v-clamp class="has-text-small" autoresize :max-lines="1">{{ item.user.name }}</v-clamp>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    item: Object,
  },
}
</script>
